import React from "react";

function Header({ split = false }) {
  return (
    <>
      <div className="header flex justify-center mt-5">
        <img
          src="https://tatabluescopesteel.com/wp-content/themes/tata/images/logo.png"
          alt="..."
        />
      </div>
    </>
  );
}

export default Header;
