import React, { useState, useEffect } from "react";
import Input from "./Input";
import {  enquireConnect } from "../constants";
import { API_URL } from "../config";

const ConnectForm = ({langData, deviceName, deviceTag}) => {
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // New state variable
  const [redirecting, setRedirecting] = useState(false);
  const [countdownTimer, setCountdownTimer] = useState(15); // 60 seconds
  const [selectedOption, setSelectedOption] = useState("");

  const [formData, setFormData] = useState({
    form_type:"Fabricator Form",
    device_name: deviceName,
    device_tag:deviceTag,
    name: "",  
    phone_no: "",
    address: "",
    pin:"",

    
  });

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const isFormDataEmpty = () => {
   if (formData.name.trim() === ""){
    return true;
   }
   else if (formData.phone_no.trim() === ""){
    return true;
   }
   else if (selectedOption.trim() === ""){
    return true;
   }
   else if (formData.pin.trim() === ""){
    return true;
   }
   else if (formData.address.trim() === ""){
    return true;
   }
    return false; 
  }

  useEffect(() => {
    let timerInterval;

    if (redirecting) {
      timerInterval = setInterval(() => {
        if (countdownTimer > 0) {
          setCountdownTimer(countdownTimer - 1);
        }
      }, 1000); // Update timer every second
    }

    return () => {
      clearInterval(timerInterval); // Clear the interval when not redirecting
    };
  }, [redirecting, countdownTimer]);

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const data ={
        form_type:"Fabricator Form",
        device_name: deviceName,
        device_tag:deviceTag,
        name: formData.name,  
        phone_no: formData.phone_no,
        address: formData.address,
        pin: formData.pin,
        enquire_for: selectedOption
        
      }
      // Perform the POST request to the specified URL
      const response = await fetch(`${API_URL}/api/connect_info/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      console.log("response", response)
      if (response.ok) {
        setIsSubmitted(true);
        setRedirecting(true);
        setSelectedOption("")
        setFormData({
          name: "",  
          phone_no: "",
          address: "",
          pin:""
        });

        setTimeout(() => {
          setIsSubmitted(false);
          setRedirecting(false);

        }, 15000); // 1 minute in milliseconds

        // Handle successful submission, e.g., show a success message
        console.log("Form submitted successfully!");
      } else {
        // Handle errors, e.g., show an error message
        console.error("Form submission failed.");
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("An error occurred while submitting the form.", error);
    } finally {
      setIsSubmitting(false);
    }
  };



  const handleCustomChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  console.log("formData", formData);


  // console.log("selectedState", selectedState);
  return (
    <div className={!isSubmitted ? `flex justify-center p-3 mt-5 drop-shadow shadow-lg` : `flex justify-center p-3 mt-5 `}>
      <div className="flex justify-center w-full ">
       {!isSubmitted ? <p className="mt-5 mb-4 text-center">
    
        </p>:null } 

        {isSubmitted ?
          (<div className="text-center mt-5">
            <p className="text-xl font-semibold text-green-600">Thanks for submitting your Enquiry!</p>
            <button onClick={() => setIsSubmitted(!isSubmitted)} className="mt-4  mb-3 bg-blue-900 text-white font-semibold  py-2 px-4 border border-blue-500 hover:border-transparent rounded">
            <i  className="fas fa-arrow-left mr-2"></i> Back
          </button>
         {redirecting ? <p className="text-sm font-semibold text-blue-400">Redirecting to webform in {countdownTimer} secs ..</p>: null}

          </div>
        ) : (
        <form className="w-full max-w-lg" onSubmit={handleSubmit}>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-name"
              >
                Name {langData?.name}
              </label>
              <Input
                handleOnChange={handleCustomChange}
                label="name"
                value={formData.name}
                placeholder={"Enter your name" + " " + langData?.name_place_holder_title}
              />
            </div>
      
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-phone_no"
              >
                Phone {langData?.phone_no}
              </label>
              <Input
                handleOnChange={handleCustomChange}
                label="phone_no"
                value={formData.phone_no}
                type="text"
                placeholder={"Enter your Phone number"  + " " + langData?.phone_no_place_holder_title}
              />
            </div>
         
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-phone_no"
              >
                Pin {langData?.pin}
              </label>
              <Input
                handleOnChange={handleCustomChange}
                label="pin"
                value={formData.pin}
                type="text"
                placeholder={"Enter your Pin"  + " " + langData?.pin_place_holder_title}
              />
            </div>

         
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                for="grid-enquiry_description"
              >
               Address {langData?.address}
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="exampleFormControlTextarea1"
                name="address"
                rows="3"
                value={formData.address}
                onChange={handleCustomChange}
                placeholder={"Enter your Description" + " " + langData?.address_place_holder_title}
                >
                  
                </textarea>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-state"
              >
                Enquire for {langData.enquire_for}
              </label>
              <div className="relative">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                  name="state"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  
                  {enquireConnect.map((choice, index) => (
                    <option key={index} value={choice.key}>
                      {choice.value} {langData && langData.enquire_for_data && langData.enquire_for_data[index].value}
                    </option>
                  ))}
                </select>

                <div  className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
         
          </div>
         
          <button type="submit" className={isFormDataEmpty() ? ` mt-5 mb-[5rem] w-full bg-gray-300 text-white font-bold py-2 px-4`: `w-full bg-blue-900 text-white font-bold py-2 px-4`}
          disabled={
            isSubmitting || 
            isFormDataEmpty()
          }
          >
          { isSubmitting ? "Submitting..." : "Submit"}
        </button>
        </form>)}
     
      </div>
    </div>
  );
};

export default ConnectForm;
