import "../App.css";
import React, { useState, useEffect } from 'react';
import EnquiryForm from "../components/EnquiryForm";
import Header from "../components/Header";
import Spinner from "../components/Spinner";
import { fetchFormLang } from "../api";
import { useLocation } from 'react-router-dom';


const Home = () => {
  
  const [showSpinner, setShowSpinner] = useState(true);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  // Get the values of the query parameters
  const languageType = params.get('language_type');
  const deviceName = params.get('device_name');

  const [langData, setlangData] = useState([])

  console.log('language_type',languageType  )
  console.log("device_name", deviceName)


  const getFormLang = async (languageType) => {
    if (languageType) {
      const data = await fetchFormLang(languageType);
      setlangData(data);
    }
  };

  useEffect(() => {
  if (languageType) {
      getFormLang(languageType)
    }  
  }, [languageType]);


  useEffect(() => {
    // Set a timeout to hide the spinner after 3 seconds
    const timeout = setTimeout(() => {
      setShowSpinner(false);
    }, 2000);

    // Clean up the timeout when the component unmounts
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="app">
    {  showSpinner ?
      <Spinner/> :
      <>
      <Header/>
      <EnquiryForm langData={langData} deviceName={deviceName}/>
      </>}
    </div>
  );
}

export default Home;
