import React from 'react'

export default function Input({value, placeholder, type, label, handleOnChange}) {
  return (
    <input
    className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
    id={`grid-${label}`}
    type={type ? type : "text"}
    value={value}
    name={label}
    onChange={handleOnChange}
    placeholder={placeholder}
    />
  )
}
