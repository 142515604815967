const DEBUG = false;

const local = "http://localhost:8000";
// const local_amed = "http://192.168.1.102:8000";
const prod = "https://enquiryadmin.easyboard.co.in";

const ITEMS_PER_PAGE = 10;
const API_URL = DEBUG ? local : prod;

export { API_URL, ITEMS_PER_PAGE };
