import { Navigate, Route, Routes, BrowserRouter } from "react-router-dom"; // Import BrowserRouter
import Home from "./pages/Home"
import Connect from "./pages/Connect"
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/?language_type=lang&devicename=device" element={<Home />} />
        <Route exact path="/connect" element={<Connect />} />
        <Route exact path="/connect/?language_type=lang&devicename=device" element={<Connect />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
