import axios from 'axios';


import { API_URL } from "./config";

export const fetchFormLang = async (lang) => {
    return axios
      .get(`${API_URL}/api/form-labels/?language=${lang}`)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return err;
      });
  }