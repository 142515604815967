import React, { useState, useEffect } from "react";
import Input from "./Input";
import { IndiaStates, IndiaCities, Skills, enquireConnect } from "../constants";
import { API_URL } from "../config";

const EnquiryForm = ({langData, deviceName, deviceTag}) => {
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // New state variable
  const [redirecting, setRedirecting] = useState(false);
  const [countdownTimer, setCountdownTimer] = useState(60); // 60 seconds

  const [formData, setFormData] = useState({
    form_type:"Enquiry Form",
    device_name: deviceName,
    device_tag:deviceTag,
    name: "",  
    email: "",
    phone_no: "",
    company: "",
    state: "",
    city: "",
    vocation: "End User",
    enquiry_type: "Bare / Colour Coated Coils",
    enquiry_description:"",
  });


  console.log("langData", langData)
  const isFormDataEmpty = () => {
   if (formData.name.trim() === ""){
    return true;
   }
   else if (formData.email.trim() === ""){
    return true;
   }
   else if (formData.phone_no.trim() === ""){
    return true;
   }
   else if (formData.state.trim() === ""){
    return true;
   }
   else if (formData.city.trim() === ""){
    return true;
   }
    return false; 
  }

  useEffect(() => {
    let timerInterval;

    if (redirecting) {
      timerInterval = setInterval(() => {
        if (countdownTimer > 0) {
          setCountdownTimer(countdownTimer - 1);
        }
      }, 1000); // Update timer every second
    }

    return () => {
      clearInterval(timerInterval); // Clear the interval when not redirecting
    };
  }, [redirecting, countdownTimer]);

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Perform the POST request to the specified URL
      const response = await fetch(`${API_URL}/api/enquiry/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsSubmitted(true);
        setRedirecting(true);
        setFormData({
          form_type:"Enquiry Form",
          device_name: deviceName,
          device_tag:deviceTag,
          name: "",
          email: "",
          phone_no: "",
          company: "",
          state: "",
          city: "",
          vocation: "End User",
          enquiry_type: "Bare / Colour Coated Coils",
          enquiry_description: "",
        });

        setTimeout(() => {
          setIsSubmitted(false);
          setRedirecting(false);

        }, 60000); // 1 minute in milliseconds

        // Handle successful submission, e.g., show a success message
        console.log("Form submitted successfully!");
      } else {
        // Handle errors, e.g., show an error message
        console.error("Form submission failed.");
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("An error occurred while submitting the form.", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStateChange = (e) => {
    const { name, value } = e.target;
    setSelectedState(value);
    const showCitiesbyStates = IndiaCities.filter(
      (cityObj) => cityObj.state === value
    );
    setCities(showCitiesbyStates || IndiaCities);
    setFormData({ ...formData, [name]: value });

  };

  const handleCustomChange = (e) => {
    console.log("e", e)
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  console.log("formData", formData);


  // console.log("selectedState", selectedState);
  return (
    <div className={!isSubmitted ? `flex justify-center p-3 mt-5 drop-shadow shadow-lg` : `flex justify-center p-3 mt-5 `}>
      <div className="form ">
       {!isSubmitted ? <p className="mt-5 mb-4 text-center">
          For General enquiries, Please fill the form below
        </p>:null } 

        {isSubmitted ?
          (<div className="text-center mt-5">
            <p className="text-xl font-semibold text-green-600">Thanks for submitting your Enquiry!</p>
            <button onClick={() => setIsSubmitted(!isSubmitted)} className="mt-4  mb-3 bg-blue-900 text-white font-semibold  py-2 px-4 border border-blue-500 hover:border-transparent rounded">
            <i  className="fas fa-arrow-left mr-2"></i> Back
          </button>
         {redirecting ? <p className="text-sm font-semibold text-blue-400">Redirecting to webform in {countdownTimer} secs ..</p>: null}

          </div>
        ) : (
        <form className="w-full max-w-lg" onSubmit={handleSubmit}>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-name"
              >
                Name {langData?.name}
              </label>
              <Input
                handleOnChange={handleCustomChange}
                label="name"
                value={formData.name}
                placeholder={"Enter your name" + " " + langData?.name_place_holder_title}
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-email"
              >
                Email {langData?.email}
              </label>
              <Input
               
                label="email"
                value={formData.email}
                type="email" handleOnChange={handleCustomChange}
                placeholder={"Enter your email"  + " " + langData?.email_place_holder_title}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-phone_no"
              >
                Phone {langData?.phone_no}
              </label>
              <Input
                handleOnChange={handleCustomChange}
                label="phone_no"
                value={formData.phone}
                type="text"
                placeholder={"Enter your Phone number"  + " " + langData?.phone_no_place_holder_title}
              />
            </div>
         
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-state"
              >
                State  {langData?.state}
              </label>
              <div className="relative">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                  name="state"
                  value={selectedState }
                  onChange={handleStateChange}
                >
                  <option value="">Select a state {langData?.state_place_holder_title} </option>
                  {Object.entries(IndiaStates).map(([stateCode, stateName]) => (
                    <option key={stateCode} value={stateName}>
                      {stateName}
                    </option>
                  ))}
                </select>

                <div  className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-city"
              >
                City  {langData?.city}
              </label>
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-city"
                name="city"
                onChange={handleCustomChange}
              >
                <option value="">{ !formData.state ? " Select a state first" : "Select a City"} {langData?.city_place_holder_title} </option>
                {cities.map((city) => (
                  <option key={city} value={city.city}>
                    {city.city}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-company"
              >
                Company (Optional)  {langData?.company}
              </label>
              <Input
                handleOnChange={handleCustomChange}
                label="company"
                value={formData.company}
                type="text"
                placeholder={"Enter your company" + " " + langData?.company_place_holder_title}
              />
            </div>
         
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 ">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-vocation"
              >
                I am {langData?.vocation}
              </label>
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-vocation"
                name="vocation"
                onChange={handleCustomChange}
              >
                {Skills.map((skill) => (
                  <option key={skill.key} value={skill.value}>
                    {skill.value}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-enquiry_type"
              >
                Enquire Type  {langData?.enquire_for}
              </label>
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-enquiry_type"
                name="enquiry_type"
                onChange={handleCustomChange}
              >
              {enquireConnect.map((item) => (
                  <option key={item.key} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                for="grid-enquiry_description"
              >
               Enquiry Description (Optional) {langData?.enquiry_description}
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="exampleFormControlTextarea1"
                name="enquiry_description"
                rows="3"
                value={formData.enquiry_description}
                onChange={handleCustomChange}
                placeholder={"Enter your Description" + " " + langData?.enquiry_description_place_holder_title
              }></textarea>
            </div>
          </div>
          <button type="submit" className={isFormDataEmpty() ? `mt-5 mb-[5rem] w-full bg-gray-300 text-white font-bold py-2 px-4`: `w-full bg-blue-900 text-white font-bold py-2 px-4`}
          disabled={
            isSubmitting || 
            isFormDataEmpty()
          }
          >
          { isSubmitting ? "Submitting..." : "Submit"}
        </button>
        </form>)}
     
      </div>
    </div>
  );
};

export default EnquiryForm;
